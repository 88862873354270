import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'
import { catchError, map, of } from 'rxjs'
import { AuthService } from '../services/auth.service'

export const authGuard: CanActivateFn = (route, state) => {
	const r = inject(Router)
	const authService = inject(AuthService)

	return authService.checkPermissions(route)
}
