<mat-nav-list>
	<a
		mat-list-item
		routerLink="/main"
		routerLinkActive="active-list-item"
		(click)="close()"
	>
		<mat-icon>home</mat-icon>
		<span>Főoldal</span>
	</a>

	<mat-divider *ngIf="isAuthenticated && isAdmin"></mat-divider>

	<a
		*ngIf="isAuthenticated && isAdmin"
		mat-list-item
		routerLink="/users"
		routerLinkActive="active-list-item"
		(click)="close()"
	>
		<mat-icon>recent_actors_rounded</mat-icon>
		<span>Emberkék</span>
	</a>

	<a
		*ngIf="isAuthenticated && isAdmin"
		mat-list-item
		routerLink="/services"
		routerLinkActive="active-list-item"
		(click)="close()"
	>
		<mat-icon>description_rounded</mat-icon>
		<span>Szolgáltatások</span>
	</a>

	<a
		*ngIf="isAuthenticated && isAdmin"
		mat-list-item
		routerLink="/passes"
		routerLinkActive="active-list-item"
		(click)="close()"
	>
		<mat-icon>import_contacts_rounded</mat-icon>
		<span>Bérletek</span>
	</a>

	<a
		*ngIf="isAuthenticated && isAdmin"
		mat-list-item
		routerLink="/incomes"
		routerLinkActive="active-list-item"
		(click)="close()"
	>
		<mat-icon>savings_rounded</mat-icon>
		<span>Bevételek</span>
	</a>

	<a
		*ngIf="!isAuthenticated"
		mat-list-item
		routerLink="/login"
		routerLinkActive="active-list-item"
		(click)="close()"
	>
		<mat-icon>login</mat-icon>
		<span>Bejelentkezés</span>
	</a>

	<mat-divider *ngIf="isAuthenticated"></mat-divider>

	<!-- <a *ngIf="isAuthenticated" mat-list-item routerLink="/my-profile" routerLinkActive="active-list-item" (click)="close()">
    <mat-icon>manage_accounts_rounded</mat-icon>
    <span>Profilom</span>
  </a> -->

	<a
		*ngIf="isAuthenticated"
		mat-list-item
		routerLink="/main"
		(click)="close(true)"
	>
		<mat-icon>logout</mat-icon>
		<span>Kijelentkezés</span>
	</a>

	<a mat-list-item disabled>
		<mat-icon>code</mat-icon>
		<span>v{{version}}</span>
	</a>

	<a mat-list-item disabled>
		<mat-icon>code</mat-icon>
		<span>{{env}}</span>
	</a>
</mat-nav-list>
