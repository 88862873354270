<mat-sidenav-container>
	<mat-sidenav #sidenav [autoFocus]="false">
		<app-nav (onCloseSidenav)="onCloseSidenav($event, sidenav)"></app-nav>
	</mat-sidenav>
	<mat-sidenav-content class="he">
		<mat-toolbar color="primary">
			<button mat-icon-button (click)="toggleSidenav(sidenav)">
				<mat-icon>menu</mat-icon>
			</button>
		</mat-toolbar>
		<!-- <span *ngIf="isLoading">
           <p class="overlay"></p>
           <span class="spinner">
             <mat-spinner
               [diameter]="40"
             ></mat-spinner>
           </span>
         </span> -->
		<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>
